﻿// max widths
.mw400 {max-width:400px!important}
.mw650 {max-width:650px!important}
.mw680 {max-width:680px!important}
.mw700 {max-width:700px!important}
.mw800 {max-width:800px!important}
.mw900 {max-width:900px!important}
.mw1000 {max-width:1000px!important}
.mw10 {max-width:10rem!important}
.mw50 {max-width:50rem!important}
.mw40 {max-width:40rem!important}
.mw45 {max-width:45rem!important}
.mw30 {max-width:30rem!important}

// % widths
.w-100 {width:100%!important}
.w-80 {width:80%!important}
.w-50 {width:50%!important; max-width:50%!important}
.w-30 {width:30%!important; max-width:30%!important}

// Reset
.wauto {width:auto!important}

// Margins
.margin-top-small {margin-top: 1rem!important}
.margin-top-med {margin-top: 2rem!important}
.margin-top-large {margin-top: 3rem!important}
.margin-top-xlarge {margin-top: 6rem!important}
.margin-top-0 {margin-top: 0rem!important}
.minus-margin-top-15 {margin-top: -15rem}

.margin-bottom-small {margin-bottom:10px!important}
.margin-bottom-med {margin-bottom:30px!important}
.margin-bottom-large {margin-bottom:40px!important}
.margin-right-1 {margin-right: 1rem!important}
.margin-right-large {margin-right: 4rem!important}
.margin-right-med {margin-right: 2rem!important}
.mt-20p {margin-top:20%!important}

.margin-left-small {margin-left:10px!important}
.margin-left-med {margin-left:20px!important}
.margin-left-large {margin-left:40px!important}

// Padding
.padding-top-0 {padding-top:0!important}
.padding-top-larger {padding-top:15%!important}
.padding-top-large {padding-top:50px!important}
.padding-top-med {padding-top:30px!important}
.padding-top-small {padding-top:10px!important}
.padding-right-small {padding-right:.5rem!important}
.padding-right-tiny {padding-right:.25rem!important}
.padding-small {padding: 1rem}


// hr
hr { border-top: 1px solid white; border-bottom: none; }

.position-relative {position: relative;}
.position-absolute {position: absolute;}
.centered {text-align: center}
.spaced {letter-spacing:.15rem!important;}
.bg-yellow {background-color:$yellow}
.relative {position: relative}
.red {color: $red!important}
.green {color: $green!important}
.blue {color: $blue!important}
.yellow {color: $yellow!important}
.dark-grey {color: $darkGrey!important}
.light-grey {color: $lightGrey!important}
.white {color: $white!important}
.align-left {text-align: left!important}
.text-align-left {text-align: left!important}
.align-right {text-align: right!important}
.border-none {border: none!important}
.border-top-red {border-top: 2px dashed $red!important}
ul.no-bullets {list-style: none!important;}


.hover {
    cursor: pointer;
    &:hover {
        strong {
            border-bottom: 1px solid $Hover!important;
        }
    }
}

.row.clear-spacing {
    margin-right: 0!important;
    margin-left: 0!important;
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, 
    .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, 
    .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, 
    .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, 
    .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, 
    .col-sm, .col-sm-auto, .col-md-1, .col-md-2, 
    .col-md-3, .col-md-4, .col-md-5, .col-md-6, 
    .col-md-7, .col-md-8, .col-md-9, .col-md-10, 
    .col-md-11, .col-md-12, .col-md, .col-md-auto, 
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, 
    .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, 
    .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, 
    .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, 
    .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, 
    .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, 
    .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-right: 0!important;
        padding-left: 0!important;
    }
}

.black {color: $black!important}
.border-bottom-none,
:hover .border-bottom-none,
.hover:hover .border-bottom-none {border-bottom: none!important;}
.nowrap {white-space: nowrap!important;}
.border-red {border: 1px solid $red}